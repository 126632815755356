<template>
	<form class="c-form-block-delete" @submit.prevent="onSubmit">
		<Form ref="form" :schema="schema" v-model="currentForm" @status="saveStatus" buttonText="Delete ad" buttonClass="btn-danger" :processing="processing"></Form>
	</form>
</template>

<script>
import Form from './index.vue';

const schema = {};

export default {
	components: {
		Form,
	},

	data: function () {
		return {
			processing: false,
			currentForm: {},
			status: null,
		};
	},

	props: {
		form: {},
	},

	computed: {
		schema: function () {
			let s = schema;

			return s;
		},
	},

	methods: {
		saveStatus: function (saveStatus) {
			this.status = saveStatus;
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (this.status.invalid) {
				return;
			}

			this.processing = true;

			let form = this.currentForm;

			this.$emit('onSubmit');

			let res = await this.$store.dispatch('ads/remove', form.id).then((err) => {
				console.log(err);
			});

			this.processing = false;
		},
	},

	created: function () {
		this.currentForm = this.form;
	},
};
</script>

<style></style>
