<template>
	<div class="c-workspace">
		<TrialExpiry v-if="isTrialOver"> </TrialExpiry>
		<Box>
			<template v-slot:header>
				<h5>Workspace settings</h5>
			</template>
			<template v-slot:body>
				<template v-if="isWorkspaceAdmin">
					<FormWorkspaceUpdate :form="workspace"></FormWorkspaceUpdate>
					<br />
					<hr />
					<FormBillingUpdate :form="workspace"></FormBillingUpdate>
					<br />
				</template>

				<template v-else>
					<p>Only the Workspace Admin can update Workspace settings.</p>
				</template>
			</template>
		</Box>

		<br />
		<br />

		<Box>
			<template v-slot:header>
				<h5>Workspace Users</h5>
			</template>
			<template v-slot:body>
				<h5>Seats</h5>
				<p>Seats can be allocated to users. Add your colleagues, workmates, etc and save ads together!</p>
				<Seats :workspace="workspace"></Seats>
				<p v-if="isWorkspaceAdmin">On your current plan, you can add upto {{ workspace.workspaceLimit }} user/s.</p>
				<br />
				<h5 v-if="isWorkspaceAdmin">Invite users</h5>
				<FormInviteUsers v-if="isWorkspaceAdmin && allowInvite"></FormInviteUsers>
				<p v-if="!allowInvite">On your current plan, you can only have {{ workspace.workspaceLimit }} members in your Workspace. Please add more seats to your Workspace.</p>
				<br />

				<template v-if="isWorkspaceAdmin">
					<h5>Add more seats</h5>
					<small> Each seat is $12 p/m </small>
					<FormSeats></FormSeats>
				</template>
			</template>
		</Box>

		<!--       
      <hr />

      <h5>Manage your team</h5>
      <Seats :workspace="workspace"></Seats>
      <FormSeats></FormSeats>
      <small>
        When you remove a user, all their saved Ads and Boards will be transferred to the Workspace Admin user.
      </small>
    </template> -->

		<hr />
		<h5>Danger Zone</h5>
		<InputButtonChoice :disabled="isDeleteDisabled" @input="onDeleteAccount" buttonClass="btn-danger" label="Permanently delete Account"></InputButtonChoice>

		<article class="article-text-sm" v-if="deleteAccountText">
			<p>{{ deleteAccountText }}</p>
		</article>
	</div>
</template>

<script>
import FormWorkspaceUpdate from '@swipekit/components/form/form-workspace-update';
import FormInviteUsers from '@swipekit/components/form/form-invite-users';
import FormBillingUpdate from '@swipekit/components/form/form-billing-update';

import Seats from './seats';
import TrialExpiry from './trial-expiry';
import InputButtonChoice from '@swipekit/components/form/input-button-choice';
import FormSeats from '@swipekit/components/form/form-seats';

import Box from '@swipekit/components/utils/box';

export default {
	components: {
		FormWorkspaceUpdate,
		FormInviteUsers,
		FormBillingUpdate,

		TrialExpiry,
		Seats,
		InputButtonChoice,
		FormSeats,

		Box,
	},

	computed: {
		isDeleteDisabled: function () {
			let workspace = this.workspace;

			let user = this.user;

			if (workspace.adminId !== user.id) {
				return true;
			}

			if (workspace.users.length > 1) {
				return true;
			}

			return false;
		},
		deleteAccountText: function () {
			let workspace = this.workspace;

			let user = this.user;

			if (workspace.adminId !== user.id) {
				return `You can't delete your Account. Please contact your Workspace Admin to delete your Account`;
			}

			if (workspace.users.length > 1) {
				return `Please remove all users in your Workspace and ensure you have cancelled your billing before deleting your Account.`;
			}

			return `This will permanently delete your account, your saved Ads & remove you from all transactional / marketing emails.`;
		},
		allowInvite: function () {
			if (!this.workspace) {
				return false;
			}
			let users = [];
			if (this.workspace.users && this.workspace.users.length) {
				users = this.workspace.users.length;
			}

			let workspaceLimit = this.workspace.workspaceLimit;

			if (users >= workspaceLimit) {
				return false;
			} else {
				return true;
			}
		},
		isTrialOver: function () {
			return this.$store.getters['users/isTrialOver'];
		},
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		isAdmin: function () {
			return this.$store.getters['users/isAdmin'];
		},
		isWorkspaceAdmin: function () {
			return this.$store.getters['workspaces/isAdmin'];
		},
	},

	methods: {
		onDeleteAccount: function () {
			this.$store.dispatch('message/onAccountDeleted');
			this.$store.dispatch('users/delete').then(() => {
				window.location.reload();
			});
		},
	},
};
</script>

<style lang="scss">
.c-workspace {
	.c-input-button-choice {
		margin: var(--spacer-sm) 0;
	}

	.c-notification {
		padding: var(--spacer-sm);
		margin: var(--spacer) 0;
		margin-top: 0;
		background-color: hsla(var(--s), 80%, 70%, 0.075);

		p:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
