<template>
	<div class="c-modal-login">
		<Modal v-model="options.active" :closable="false">
			<svg class="c-modal-login__bg" width="100%" height="100%">
				<pattern id="pattern-circles" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
					<circle id="pattern-circle" cx="10" cy="10" r="1.6257413380501518" fill="currentColor"></circle>
				</pattern>

				<rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)"></rect>
			</svg>
			<div class="c-modal-login__main">
				<div class="c-modal-login__header">
					<a class="logo" href="https://swipekit.app">
						<svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M224.529 212.063C244.13 189.575 256 160.174 256 128C256 57.3076 198.692 0 128 0C57.3076 0 0 57.3076 0 128C0 198.692 57.3076 256 128 256C143.695 256 158.73 253.175 172.625 248.006C173.014 247.744 173.386 247.476 173.74 247.2C177.54 244.2 179.44 240.5 179.44 236.1V233.4C179.44 228.8 177.44 225.1 173.44 222.3C169.44 219.3 162.54 216.7 152.74 214.5C142.94 212.3 129.54 210 112.54 207.6C94.3398 205 79.1398 201.5 66.9398 197.1C54.9398 192.5 45.8398 186.4 39.6398 178.8C33.4398 171 30.3398 161.3 30.3398 149.7V146.1C30.3398 133.5 34.1398 122.8 41.7398 114C49.5398 105.2 60.4398 98.5 74.4398 93.9C88.4398 89.3 105.24 87 124.84 87H130.84C149.84 87 166.34 89.4 180.34 94.2C194.34 99 205.24 106.1 213.04 115.5C220.84 124.9 224.74 136.8 224.74 151.2V160.2H176.44V151.2C176.44 145.2 174.54 140.3 170.74 136.5C167.14 132.7 161.84 129.9 154.84 128.1C148.04 126.1 140.04 125.1 130.84 125.1H124.84C115.24 125.1 107.04 125.9 100.24 127.5C93.6398 129.1 88.4398 131.4 84.6398 134.4C81.0398 137.2 79.2398 140.5 79.2398 144.3V147.3C79.2398 150.7 80.2398 153.7 82.2399 156.3C84.2398 158.7 87.6398 160.8 92.4398 162.6C97.2398 164.2 103.84 165.8 112.24 167.4C120.84 169 131.74 170.7 144.94 172.5C164.34 175.7 180.14 179.8 192.34 184.8C204.74 189.6 213.84 195.9 219.64 203.7C221.601 206.27 223.231 209.058 224.529 212.063Z"
								fill="currentColor"
							/>
						</svg>

						<h3>Swipekit</h3>
					</a>
				</div>
				<div class="c-modal-login__left-panel">
					<div class="container-fluid">
						<div v-if="tab === 'login'">
							<h3>Login</h3>
							<FormLogin @onSubmit="onSubmit"></FormLogin>
							<hr />
							<p>
								Not a member yet?
								<a @click="tab = 'signup'">Signup now</a>
							</p>
							<p>
								Forgot your password?
								<a @click="tab = 'passwordreset'">Reset it now</a>
							</p>
						</div>
						<div v-if="tab === 'signup'">
							<h3>Signup</h3>
							<FormSignup :presetEmail="presetEmail" @onSubmit="onSubmit"></FormSignup>
							<div class="referral-extension" v-if="trialExtension && referralPartner">
								<h1>🎁</h1>
								Thanks to our sponsoring partner, your account will have 30 days of free trial!
							</div>

							<hr />

							<p>Have an account? <a @click="tab = 'login'">Log in now.</a></p>
						</div>
						<div v-if="tab === 'passwordreset'">
							<h3>Password reset</h3>
							<p class="article-text">Forgot your password? Reset it by filling this form.</p>
							<FormPasswordReset @onSubmit="onPasswordResetSubmit"></FormPasswordReset>
							<hr />
							<p>
								<a @click="tab = 'login'">Back</a>
							</p>
						</div>
						<div v-if="tab === 'afterpasswordreset'">
							<h3>Check your email.</h3>
							<p>Your password reset link has been sent to your email inbox.</p>
							<p>Back to <a @click="tab = 'login'">log in.</a></p>
						</div>
						<div v-if="tab === 'onpasswordreset'">
							<h3>Set a new password</h3>
							<FormPassword @onSubmit="onSubmit"></FormPassword>
							<br />
							<p>Back to <a @click="tab = 'login'">log in.</a></p>
						</div>
						<div v-if="tab === 'oninvite'">
							<h3>Welcome to Swipekit!</h3>
							<p class="article-text">Please create your account to join this workspace.</p>
							<FormInviteUserSetting :inviteCode="$route.query.invite" @onSubmit="onSubmit"></FormInviteUserSetting>
						</div>
					</div>
				</div>

				<div class="c-modal-login__footer">
					<a href="https://swipekit.app/terms" target="_blank">Terms</a>
					<a href="https://swipekit.app/privacy-policy" target="_blank">Privacy Policy</a>
					<a href="https://swipekit.app/help" target="_blank">Help</a>
				</div>
			</div>
			<!-- <div class="c-modal-login__right-panel" v-if="!$route.query.invite">
        <div class="container-fluid">
          <div class="c-modal-login__testimonial">
            <p>
              “Swipekit helped us save tons of time. It allowed the marketing and design teams to seamless collaborate
              with one another from within a single platform.”
            </p>
            <img src="/testimonials/clinicgrower.jpeg" />
            <article>
              <span> James Kim </span>
              <p>Digital Marketing Manager • <a href="https://clinicgrower.com/" target="_blank">ClinicGrower</a></p>
            </article>
          </div>
        </div>
      </div> -->
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';
import FormLogin from '@swipekit/components/form/form-login.vue';
import FormSignup from '@swipekit/components/form/form-signup.vue';
import FormPasswordReset from '@swipekit/components/form/form-password-reset.vue';
import FormPassword from '@swipekit/components/form/form-password';
import FormInviteUserSetting from '@swipekit/components/form/form-invite-user-setting';

export default {
	components: {
		Modal,
		FormLogin,
		FormSignup,
		FormPasswordReset,
		FormPassword,
		FormInviteUserSetting,
	},
	data: function () {
		return {
			tab: 'signup',
			presetEmail: '',
			options: {
				name: 'modal-login',
				active: false,
			},
		};
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		isAuth: {
			handler: function () {
				if (this.slug) {
					this.options.active = false;
					return;
				}
				if (!this.isAuth) {
					setTimeout(() => {
						this.options.active = true;
					}, 1000);
				}
			},
			immediate: true,
		},
	},

	computed: {
		isAuth: function () {
			return this.$store.getters['users/isAuth'];
		},
		slug: function () {
			return this.$route.params.slug;
		},
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		trialExtension: function () {
			return this.$store.getters['app/trialExtension'];
		},
		referralPartner: function () {
			return this.$store.getters['app/referralPartner'];
		},
	},

	methods: {
		onSubmit: function () {
			this.tab = 'login';
			this.options.active = false;

			this.shouldShowOnboarding();
		},
		activate: function () {
			this.options.active = true;
		},
		onPasswordResetSubmit: function () {
			this.tab = 'afterpasswordreset';
		},
		shouldShowOnboarding: function () {
			let workspace = this.workspace;

			if (!workspace.activated) {
				this.$store.dispatch('app/toggleOnboarding', true);
			} else {
				this.$store.dispatch('app/toggleOnboarding', false);
			}
		},
	},

	mounted: function () {
		let query = this.$route.query;
		if (query.signup || query.onboard) {
			this.tab = 'signup';
		}
		if (query.resetpassword) {
			this.tab = 'onpasswordreset';
			//this.$store.dispatch("message/onPasswordReset");
		}
		if (query.invite) {
			this.tab = 'oninvite';
		}
		if (query.email) {
			this.tab = 'signup';
			this.presetEmail = query.email;
		}
	},
};
</script>

<style lang="scss">
.c-modal-login {
	.vfm {
		.vfm-fade-enter-active,
		.vfm-fade-leave-active {
			transition: all 350ms cubic-bezier(0.27, 0.005, 0.365, 0.935);
		}
		.vfm-fade-enter,
		.vfm-fade-leave-to {
			opacity: 0;
			transform: translateY(100%);
		}

		.vfm__overlay {
			position: absolute;
			background-color: transparent !important;
			background-image: url('/media/cover.jpg');
			background-size: cover;
			background-position: center center;
			opacity: 0.75;

			// &:after {
			//   content: "";
			//   position: absolute;
			//   top: 0;
			//   left: 0;
			//   width: 100%;
			//   height: 100%;
			//   background-image: url('data:image/svg+xml,<svg width="100%" height="100%"><pattern id="pattern-circles" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse" ><circle id="pattern-circle" cx="10" cy="10" r="1.6257413380501518" fill="red"></circle></pattern><rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(%23pattern-circles)"></rect></svg>');
			//   background-repeat: repeat;
			// }

			&.non-closable {
				opacity: 1;
			}
		}
		.modal-container {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0;
			overflow: hidden;
		}
		.modal-content {
			width: 100%;
			height: 100%;
			margin: 0;
			border-radius: 0;
			border: none;
			background: transparent;
		}
	}

	.modal__title {
		padding: 0;
	}

	.modal__content {
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-y: auto;

		.c-form-signup {
			.c-input-text {
				padding-bottom: var(--margin-sm);
			}
		}

		.article-text {
			text-align: center;
		}

		> p {
			margin-bottom: 0;
			font-weight: 500;
		}

		hr {
			&:after {
				background-color: var(--color-bg-6);
			}
		}

		ul {
			margin: 0;
			padding: 0;
			margin-top: var(--spacer-sm);
			margin-bottom: var(--spacer-sm);
			border: var(--g9) solid 1px;
			border-radius: var(--border-radius-lg);
			background-color: var(--g12);
			font-size: var(--font-size-sm);

			li {
				display: flex;

				padding: var(--margin-lg);
				list-style: none;

				border-bottom: var(--g9) solid 1px;

				> span {
					margin-right: var(--margin);
				}

				p {
					color: var(--g3);
				}
			}
		}
	}

	.referral-extension {
		margin: var(--margin-lg) 0;
		border: var(--color-primary) solid 1px;
		background-color: var(--color-bg-2);
		padding: var(--spacer-sm) var(--margin-lg);
		border-radius: var(--border-radius);

		text-align: center;

		h1 {
			margin-bottom: var(--margin);
		}
	}

	&__main {
		position: relative;
		z-index: 1;
		max-width: 400px;
		margin: 0 auto;
	}

	&__bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: var(--color-bg-1);
		opacity: 0.35;

		pointer-events: none;
	}

	&__header {
		padding: var(--spacer) 0;
		padding-top: var(--spacer-lg);

		h3 {
			font-size: var(--font-size-xxl);
		}

		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--color-font);
		}

		svg {
			width: 48px;
			height: 48px;
			margin-right: var(--margin-lg);
		}

		h3 {
			margin: 0;
			display: inline-block;
			font-weight: 600;
		}
	}

	&__footer {
		padding: var(--spacer-sm) 0;
		display: flex;
		justify-content: center;

		a {
			display: inline-block;
			margin: 0 var(--margin);
			color: var(--color-font);
			text-decoration: underline;
		}
	}

	&__left-panel {
		width: 100%;
		padding: var(--spacer);
		background-color: rgba(255, 255, 255, 0.85);

		border-radius: 20px;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 0px 0px 0.4px, rgba(0, 0, 0, 0.08) 0px 2px 4px -1px, rgba(0, 0, 0, 0.1) 0px 5px 38px -2px;

		h3 {
			text-align: center;
			margin-bottom: var(--margin-lg);
		}

		.logo {
			width: var(--spacer-lg);
			height: var(--spacer-lg);
			display: block;
			margin: 0 auto;
			margin-bottom: var(--spacer-sm);

			img {
				width: 100%;
				height: 100%;
				border-radius: var(--border-radius-full);
			}
		}
	}

	&__right-panel {
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		backdrop-filter: blur(10px);
		border-right: rgba(0, 0, 0, 0.075) solid 1px;
		background-color: rgba(255, 255, 255, 0.65);
		//border-right: var(--color-bg-4) solid 1px;
	}

	&__testimonial {
		display: flex;
		flex-direction: column;
		> p {
			font-size: var(--font-size-lg);
			text-align: center;
			color: var(--font-color);
			padding: 0 var(--spacer);
		}

		img {
			display: block;
			width: var(--spacer-lg);
			height: var(--spacer-lg);
			border-radius: var(--border-radius-full);
			margin: 0 auto;
		}

		article {
			margin-top: var(--spacer-sm);
			text-align: center;

			span {
				font-weight: 500;
			}

			p {
				opacity: 0.75;
			}

			a {
				color: var(--color-font);
			}
		}
	}

	a {
		cursor: pointer;
	}

	@media screen and (max-width: 800px) {
		.vfm {
			.modal-container {
				top: 0px;
				left: 0px;
				width: calc(100% - 0px);
				height: calc(100% - 0px);
				max-width: initial;
			}
		}

		.modal__content {
			overflow-y: auto;
		}

		&__left-panel {
			position: static;
			width: 100%;
			height: auto;
			left: 0;
			padding: var(--spacer-lg) 0;
			border-right: none;

			border-bottom: var(--color-bg-4) solid 1px;

			border-radius: 0;

			.logo {
				margin-bottom: var(--spacer-sm);
			}

			.container-fluid > div {
				padding: 0;
			}
		}

		&__right-panel {
			position: static;
			width: 100%;
			height: auto;
			left: 0;
			padding: var(--spacer-lg) 0;
			border-right: none;
			backdrop-filter: blur(12px);
			background-color: rgba(255, 255, 255, 0.75);
		}
	}
}
</style>
