<template>
	<div class="form-group c-input-boards">
		<label v-if="label" class="form__label" :for="slug">{{ label }}</label>
		<div class="c-input-boards__inner">
			<InputText v-if="boards.length > 10" placeholder="Search boards" v-model="query"></InputText>
			<div class="c-input-boards__scroll">
				<div class="c-input-boards__scroll__inner">
					<div
						v-for="board in computedBoards"
						:key="board.id"
						:class="['c-input-boards__item', { active: selected.includes(board.id) }]"
						@click="toggleSlug(board.id)"
						tabindex="0"
						@keydown.enter="toggleSlug(board.id)"
						@keydown.space="toggleSlug(board.id)"
					>
						<input type="checkbox" name="input-boards" v-model="selected" :value="board.id" />
						<label>
							<article>
								<p>{{ board.name }}</p>
								<span> {{ board.createdAt }} </span>
							</article>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M17.6585 7.24744C18.0741 7.61112 18.1163 8.24288 17.7526 8.65852L10.7526 16.6585C10.5703 16.8668 10.3099 16.9902 10.0333 16.9995C9.75666 17.0087 9.4886 16.9028 9.29289 16.7071L6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929C6.68342 11.9024 7.31658 11.9024 7.70711 12.2929L9.95129 14.5371L16.2474 7.34151C16.6111 6.92587 17.2429 6.88375 17.6585 7.24744Z"
									fill="currentColor"
								/>
							</svg>
						</label>
					</div>
					<div class="c-input-boards__empty-state" v-if="!computedBoards || computedBoards.length === 0">
						<strong> No boards found </strong>
						<p>Created boards will show up here.</p>
					</div>
				</div>
			</div>
		</div>
		<InputError v-if="hasError" :message="errorMessage"></InputError>
		<InputDescription v-if="description" :message="description"></InputDescription>
	</div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@swipekit/lib/slugify.js';

import Fuse from 'fuse.js';
import InputText from './input-text';

import { format } from 'date-fns';

export default {
	components: {
		InputError,
		InputDescription,
		InputText,
	},

	data: function () {
		return {
			query: '',
			selected: [],
		};
	},

	watch: {
		selected: function () {
			this.onChange({
				target: {
					value: this.selected,
				},
			});
		},
		value: function () {
			let valueArr = this.value || [];

			valueArr = JSON.parse(JSON.stringify(valueArr));

			let currentArr = this.selected || [];

			if (valueArr.length !== currentArr.length) {
				let temp = valueArr.map((board) => {
					if (typeof board === 'number') {
						return board;
					}
					return board.boardId;
				});
				this.selected = temp;
			}
		},
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		validation: {},
		value: {},
		placeholder: {
			type: String,
			default: '',
		},
		noStyle: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: '',
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		errorMessage: {},
	},

	computed: {
		computedBoards: function () {
			let boards = this.boards;

			boards = JSON.parse(JSON.stringify(boards));

			if (this.query) {
				let options = {
					keys: ['name'],
				};
				let fuse = new Fuse(boards, options);
				let items = fuse.search(this.query);
				items = items.map((i) => {
					return i.item;
				});
				return items;
			}

			for (let i = 0; i < boards.length; i++) {
				boards[i].createdAt = format(new Date(boards[i].createdAt), `do MMM yyyy`);
			}

			return boards;
		},
		slug: function () {
			return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
		},
		boards: function () {
			return this.$store.getters['boards/filtered'];
		},
	},

	methods: {
		toggleSlug: function (slug) {
			if (this.selected.includes(slug)) {
				let i = this.selected.indexOf(slug);
				if (i > -1) {
					this.selected.splice(i, 1);
				}
			} else {
				this.selected.push(slug);
			}
		},
		slugify: function (str) {
			return str;
		},
		onChange: function (e) {
			let value = e.target.value;
			value = JSON.parse(JSON.stringify(value));
			this.$emit('input', value);
		},
	},

	created: function () {
		let value = JSON.parse(JSON.stringify(this.value));

		if (value && value.length > 0) {
			let temp = value.map((board) => {
				if (typeof board === 'number') {
					return board;
				}
				return board.boardId;
			});
			this.selected = temp;
		}
	},
};
</script>

<style lang="scss">
.c-input-boards {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: var(--margin-lg);

	.c-input-text {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		padding: var(--margin-lg);
		padding-bottom: var(--margin-lg);

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--color-bg-6);
		}

		.form-control {
			margin-bottom: 0;
		}
	}

	&__inner {
		position: relative;
		width: 100%;
		height: 250px;
	}

	&__scroll {
		position: absolute;
		top: 52px;
		left: 0;
		width: 100%;
		height: 198px;
		display: flex;
		flex-direction: column;
		width: 100%;
		//overflow: hidden;
		overflow-y: auto;

		&__inner {
			min-height: 100%;
		}
	}

	&__empty-state {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__item {
		label {
			display: grid;
			grid-template-columns: calc(100% - 32px) 32px;
			align-items: center;
			padding: 4px 8px;
			margin: 0;
			font-size: 14px;
			text-align: center;
			text-align: left;
			cursor: pointer;
			user-select: none;
			transition: all var(--transition-time-sm) linear;

			article {
				p {
					max-width: calc(100% - 32px);
					margin-top: 0;
					margin-bottom: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 1.2;
				}

				span {
					max-width: calc(100% - 32px);
					font-size: 12px;
					color: rgba(0, 0, 0, 0.8);
					opacity: 0.75;
				}
			}

			svg {
				display: none;
			}

			&:hover {
				background-color: var(--color-button-primary-light);

				article {
					p,
					span {
						color: var(--color-button-font);
					}
				}
			}

			&:focus {
				outline: none;
			}
		}

		&:focus {
			outline: none;
		}

		&.active {
			label {
				background-color: var(--color-button-primary-light);

				svg {
					display: block;
					color: var(--color-button-font);
				}

				article {
					p,
					span {
						color: var(--color-button-font);
					}
				}
			}
		}
	}
}
</style>
