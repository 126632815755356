<template>
	<div :class="['c-transcript-view', { accordion: isAccordion === true }]">
		<div :class="['c-transcript-view__wrap', { expanded: expanded === true }]">
			<div :class="['c-transcript-view__row', { oldstyle: oldStyledTranscript === true }]" v-for="(text, i) in texts" :key="i">
				<span v-if="!oldStyledTranscript">{{ toSeconds(text.start) }}</span>
				<article>
					{{ text.text || text }}
				</article>
			</div>
		</div>
		<div :class="['c-transcript-view__expand', { expanded: expanded === true }]">
			<span class="btn" @click="expand"> {{ expandText }} </span>
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			expanded: false,
		};
	},

	props: {
		texts: {},
	},

	computed: {
		isAccordion: function () {
			let texts = this.texts;

			if (texts && texts.length > 3) {
				return true;
			}
		},
		expandText: function () {
			if (this.expanded) {
				return `Hide transcript`;
			}
			return `View full transcript`;
		},
		oldStyledTranscript: function () {
			if (!this.texts) {
				return;
			}

			let texts = this.texts;

			let text = texts[0];

			if (typeof text === 'string') {
				return true;
			}
		},
	},

	methods: {
		expand: function () {
			this.expanded = !this.expanded;
		},
		toSeconds: function (milliseconds) {
			// Calculate minutes and seconds
			const minutes = Math.floor(milliseconds / 60000); // 60000 milliseconds in a minute
			const seconds = Math.floor((milliseconds % 60000) / 1000); // remaining seconds

			// Format minutes and seconds to always be two digits
			const formattedMinutes = minutes.toString().padStart(2, '0');
			const formattedSeconds = seconds.toString().padStart(2, '0');

			return `${formattedMinutes}:${formattedSeconds}`;
		},
	},
};
</script>

<style lang="scss">
.c-transcript-view {
	&.accordion {
		.c-transcript-view__wrap {
			max-height: 180px;

			&:after {
				display: block;
			}
		}

		.c-transcript-view__expand {
			display: flex;
		}
	}

	&__wrap {
		position: relative;
		overflow: hidden;
		padding-bottom: var(--margin);

		transition: all var(--transition-time) ease-out;

		&:after {
			content: '';
			display: none;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60px;
			background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			pointer-events: none;
			transition: all var(--transition-time) ease-out;
		}

		&.expanded {
			max-height: initial !important;

			&:after {
				opacity: 0;
			}
		}
	}

	&__expand {
		width: 100%;
		display: none;
		align-items: center;
		justify-content: center;
		border-top: var(--color-bg-4) solid 1px;
		transition: all var(--transition-time) ease-out;

		.btn {
			transform: translateY(-50%);
			font-weight: 500;
			transition: all var(--transition-time) ease-out;
		}

		&.expanded {
			border-top-color: transparent;

			.btn {
				transform: translateY(0);
			}
		}
	}

	&__row {
		display: grid;
		grid-template-columns: 52px 1fr;
		grid-column-gap: var(--margin-lg);
		font-family: var(--font-family-monospace);
		font-size: var(--font-size-sm);

		span {
			color: var(--color-secondary);
		}

		article {
		}

		&:not(:last-child) {
			margin-bottom: var(--margin-lg);
		}

		&.oldstyle {
			grid-template-columns: 1fr;
		}
	}
}
</style>
