<template>
	<div class="c-board" v-if="board">
		<PageHeader byline="Board">
			<template v-slot:title> {{ board.name }} </template>
			<template v-slot:footer>
				<div class="c-board__description" v-if="board.description">
					<p class="article-text">{{ board.description }}</p>
				</div>
				<div class="c-board__buttons">
					<a v-if="board.public" class="btn btn-icon btn-box" target="_blank" :href="`https://swipekit.app/boards/${board.slug}`">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10 5.5L10.7514 4.75837C13.0959 2.41387 16.8971 2.41388 19.2416 4.75837C21.5861 7.10287 21.5861 10.9041 19.2416 13.2486L18.5 14M5.5 10L4.75837 10.7514C2.41387 13.0959 2.41388 16.8971 4.75838 19.2416C7.10287 21.5861 10.9041 21.5861 13.2486 19.2416L14 18.5M10 14L14 10"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<span>Open share link</span>
					</a>
					<a class="btn btn-icon btn-box" href="#" @click.prevent="editBoard">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M13.5 6L15.5858 3.91422C16.3668 3.13317 17.6332 3.13317 18.4142 3.91422L20.0858 5.58579C20.8668 6.36684 20.8668 7.63317 20.0858 8.41422L18 10.5M13.5 6L3.29289 16.2071C3.10536 16.3946 3 16.649 3 16.9142V21H7.08579C7.351 21 7.60536 20.8946 7.79289 20.7071L18 10.5M13.5 6L18 10.5"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>

						<span>Update board</span>
					</a>
				</div>
			</template>
		</PageHeader>
		<List :standalone="standalone" v-if="init" ref="list" :board="board" :adIds="adIds" @onSubmit="onSave"></List>
		<Share v-if="board && board.shareable" :boardId="board.id"></Share>
	</div>
</template>

<script>
import PageHeader from '@swipekit/components/utils/page-header';
import List from './list';
import api from '@swipekit/lib/api';
import Share from './share';

export default {
	name: 'Multiplayer',

	components: {
		PageHeader,
		List,
		Share,
	},

	data: function () {
		return {
			init: false,
			adIds: [],
		};
	},

	props: {
		standalone: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		slug: function () {
			return this.$route.params.slug;
		},
		board: function () {
			if (!this.slug) {
				return;
			}
			return this.$store.getters['boards/boardBySlug'](this.slug);
		},
	},

	methods: {
		editBoard: function () {
			this.$store.dispatch('boards/setEdit', this.board.id);
		},
		onSave: function (e) {
			const blockId = e.blockId;

			this.$store.dispatch('ads/refreshAll');

			this.adIds.push(blockId);
		},
		onEnd: function () {
			this.$refs.list.onEnd();
		},
		async getIds() {
			let ids = await api.ads.getAdIds();

			this.adIds = ids;

			this.init = true;
		},
	},

	mounted: function () {
		this.getIds();
	},
};
</script>

<style lang="scss">
.c-board {
	height: 100vh;
	position: relative;
	overflow-y: auto;

	&__buttons {
		background-color: var(--color-bg-1);
		padding: var(--margin-lg);
		margin-top: var(--spacer-sm);
		display: inline-flex;
		align-items: center;
		box-shadow: var(--item-box-shadow);
		border-radius: var(--border-radius-lg);

		a {
			margin: 0 var(--margin);
		}
	}

	&__description {
		text-align: left;
		max-width: 460px;
		margin: 0 auto;
		margin-top: var(--spacer-sm);
		opacity: 0.85;
		white-space: break-spaces;
		padding: var(--spacer-sm);
		border: rgba(0, 0, 0, 0.1) solid 1px;
		border-radius: 10px;

		.article-text {
			margin-top: 0;
			font-size: 16px;
		}
	}
}
</style>
