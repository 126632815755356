<template>
	<div class="c-block-view-links">
		<a v-if="adLibraryLink" class="btn btn-primary btn-icon" :href="adLibraryLink" target="_blank">
			<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10 5.5L10.7514 4.75837C13.0959 2.41387 16.8971 2.41388 19.2416 4.75837C21.5861 7.10287 21.5861 10.9041 19.2416 13.2486L18.5 14M5.5 10L4.75837 10.7514C2.41387 13.0959 2.41388 16.8971 4.75838 19.2416C7.10287 21.5861 10.9041 21.5861 13.2486 19.2416L14 18.5M10 14L14 10"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<span> Ad Library </span>
		</a>
		<a v-if="landingPage" class="btn btn-primary btn-icon" :href="landingPage" target="_blank">
			<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10 5.5L10.7514 4.75837C13.0959 2.41387 16.8971 2.41388 19.2416 4.75837C21.5861 7.10287 21.5861 10.9041 19.2416 13.2486L18.5 14M5.5 10L4.75837 10.7514C2.41387 13.0959 2.41388 16.8971 4.75838 19.2416C7.10287 21.5861 10.9041 21.5861 13.2486 19.2416L14 18.5M10 14L14 10"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<span> Landing page </span>
		</a>

		<a v-if="brandPage" class="btn btn-primary btn-icon" :href="brandPage" target="_blank">
			<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10 5.5L10.7514 4.75837C13.0959 2.41387 16.8971 2.41388 19.2416 4.75837C21.5861 7.10287 21.5861 10.9041 19.2416 13.2486L18.5 14M5.5 10L4.75837 10.7514C2.41387 13.0959 2.41388 16.8971 4.75838 19.2416C7.10287 21.5861 10.9041 21.5861 13.2486 19.2416L14 18.5M10 14L14 10"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<span> Facebook brand page </span>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		block: {},
	},

	computed: {
		adLibraryLink: function () {
			if (this.block.type === 'facebook') {
				let str = `https://www.facebook.com/ads/library/?id=${this.block.metaId}`;
				let meta = this.block.meta;
				if (!meta) {
					return '';
				}
				if (meta.pageId) {
					str = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${meta.pageId}&search_type=page&media_type=all`;
				}

				return str;
			}
			if (this.block.type === 'tiktok-library') {
				return this.block.source;
			}
			if (this.block.type === 'tiktok-top-ads') {
				return this.block.source;
			}
		},
		landingPage: function () {
			let ctaLink = this.block.ctaLink;
			if (ctaLink) {
				return ctaLink;
			}

			if (this.block.meta && this.block.meta.ctaLink) {
				return this.block.meta.ctaLink;
			}

			return false;
		},
		brandPage: function () {
			if (this.block.type === 'facebook' && this.block.source) {
				return this.block.source;
			}
		},
	},
};
</script>

<style lang="scss">
.c-block-view-links {
	display: flex;
	flex-wrap: wrap;
	margin-top: var(--spacer-sm);
	margin-bottom: var(--spacer-sm);

	a {
		margin-right: var(--margin);
		margin-bottom: var(--margin);

		svg {
			margin-left: var(--margin-sm);
		}
	}
}
</style>
