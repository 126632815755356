<template>
	<div class="c-onboarding2-integrations">
		<Page :i="4">
			<!-- <span class="c-byline"> Tutorial #5: Boards </span> -->
			<h1>Welcome to Swipekit, {{ user.name }}</h1>
			<article class="article-text">
				<p>You have been invited to {{ admin.name }}'s workspace.</p>
				<p>Using Swipekit, you can organize your creative workflow in one place.</p>
			</article>
			<div class="c-onboarding2-integrations__item">
				<img src="/brands/tiktok.png" />
				<span> Save Ads from the Tiktok Creative Center. </span>
			</div>
			<div class="c-onboarding2-integrations__item">
				<img src="/brands/tiktok.png" />
				<span> Save Ads from the Tiktok Ad library & Commercial Content library. </span>
			</div>
			<!-- <div class="c-onboarding2-integrations__item">
				<img src="/brands/google.svg" />
				<span> Save Ads from the Google Ad transparency library. </span>
			</div> -->
			<div class="c-onboarding2-integrations__item">
				<img src="/brands/linkedin.png" />
				<span> Save Ads from the Linkedin Ad library. </span>
			</div>
			<article class="article-text">
				<p>Swipekit will also extract more information about the ad after the save.</p>
			</article>
			<div class="c-onboarding2-integrations__item">
				<span> Landing page screenshot is saved. </span>
			</div>
			<div class="c-onboarding2-integrations__item">
				<span> Video Transcript is automatically generated. </span>
			</div>

			<article class="article-text">
				<p>You can also keep tabs on your competitors.</p>
			</article>
			<div class="c-onboarding2-integrations__item">
				<span>Use Brand Tracker to automatically save ads from competitor brand accounts. </span>
			</div>
			<div class="c-onboarding2-integrations__item">
				<span>Use Brand Tracker's insights to get competitor analysis summary. </span>
			</div>

			<ButtonCta @onClick="$emit('onNext')">
				<span> Finish onboarding </span>
			</ButtonCta>
		</Page>
	</div>
</template>

<script>
import Page from './page';
import ButtonCta from './button-cta.vue';
import Item from '@swipekit/components/item';

export default {
	components: {
		Page,
		ButtonCta,
		Item,
	},

	props: {
		tempAd: {},
	},

	computed: {
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		admin: function () {
			return this.$store.getters['workspaces/admin'];
		},
	},

	methods: {
		onClick: function (e) {
			if (e && !e.target) {
				this.$store.dispatch('message/sendMessage', {
					message: 'Board created',
					status: 'success',
				});
			}
			this.$emit('onNext');
		},
	},
};
</script>

<style lang="scss">
.c-onboarding2-invitee {
	&__item {
		display: flex;
		padding: var(--margin-lg);
		margin-bottom: var(--margin-lg);
		border-radius: var(--border-radius-lg);
		border: var(--color-bg-4) solid 1px;

		img {
			width: 24px;
			min-width: 24px;
			height: 24px;
			margin-right: var(--margin-lg);
		}
	}

	.article-text {
		margin-top: var(--spacer-sm);
	}
}
</style>
