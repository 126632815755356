<template>
	<div ref="item" :class="['c-brands-item']">
		<router-link class="c-brands-item__wrapper" :to="`/brands/${item.slug}`">
			<div class="c-brands-item__inner">
				<img :src="`${assetUrl}/${item.logo}`" />
				<article>
					<strong>
						{{ item.name }}
					</strong>
					<span :class="['status', item.status.toLowerCase()]">
						<span :class="[item.status.toLowerCase()]"> </span>
						<!-- <span v-if="item.status === 'ACTIVE'"> Active </span>
						<span v-else> Inactive </span> -->
					</span>
					<span :title="`This brand is being tracked since ${formattedDate}`"> Added {{ formattedDate }} </span>
					<span v-if="lastUpdated"> • </span>
					<span v-if="lastUpdated"> Updated {{ lastUpdated }} </span>
				</article>
				<span> {{ item.blockCount }} Ads </span>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289L17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12C18 12.2652 17.8946 12.5196 17.7071 12.7071L9.70711 20.7071C9.31658 21.0976 8.68342 21.0976 8.29289 20.7071C7.90237 20.3166 7.90237 19.6834 8.29289 19.2929L15.5858 12L8.29289 4.70711C7.90237 4.31658 7.90237 3.68342 8.29289 3.29289Z"
						fill="currentColor"
					/>
				</svg>
			</div>
		</router-link>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
	components: {},

	props: {
		item: {},
		standalone: {
			type: Boolean,
			default: false,
		},
		shared: {
			type: Boolean,
			default: false,
		},
		multiplayer: {
			type: Boolean,
			default: false,
		},
		view: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},

	computed: {
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
		formattedDate: function () {
			let date = this.item.createdAt;

			if (!date) {
				return '';
			}

			let formattedDate = dayjs(date).fromNow();

			return formattedDate;
		},
		lastUpdated: function () {
			let lastCheckedAt = this.item.lastCheckedAt;

			if (!lastCheckedAt) {
				return;
			}

			let formattedDate = dayjs(lastCheckedAt).fromNow();

			return formattedDate;
		},
	},
};
</script>

<style lang="scss">
.c-brands-item {
	width: calc(var(--header-width) + 360px);
	margin: 0 auto;
	margin-bottom: var(--margin);
	height: auto;

	&__wrapper {
		min-height: 68px;
		position: relative;
		display: block;
		color: var(--color-font);
		background-color: var(--color-bg-3);
		border-radius: 10px;
		transition: all var(--transition-time-sm) linear;

		&:hover,
		&:active {
			background-color: var(--color-bg-4);
			text-decoration: none !important;
		}
	}

	&__inner {
		padding: var(--margin-lg);

		display: grid;
		grid-template-columns: 48px 1fr 74px 24px;
		align-items: center;

		article {
			padding-left: var(--margin-lg);
			strong {
				display: block;
				font-size: var(--font-size-lg);
				font-weight: 500;
			}
			> span {
				font-size: var(--font-size-sm);

				&:last-of-type {
					opacity: 0.8;
				}
			}

			.status {
				margin: 0;

				> span:first-child {
					display: inline-block;
					width: 12px;
					height: 12px;
					background-color: var(--color-success);
					border-radius: 99px;

					&.inactive {
						background-color: var(--color-danger);
					}
				}
			}
		}

		img {
			width: 100%;
			height: 48px;
			object-fit: cover;
			border-radius: 100%;
		}

		span {
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
</style>
