<template>
	<div class="c-tag-selector">
		<InputTags :value="currentTags" @input="onInput"></InputTags>
		<article class="c-tag-selector__status">
			<span class="c-spinner" v-if="processing"></span>
			<span>
				{{ statusText }}
			</span>
		</article>
	</div>
</template>

<script>
import InputTags from '@swipekit/components/form/input-tags';

export default {
	components: {
		InputTags,
	},

	data: function () {
		return {
			processing: false,
		};
	},

	computed: {
		statusText: function () {
			if (this.processing) {
				return `Updating ad`;
			}
			return `Edit tags`;
		},
		ad: function () {
			let id = this.adId;

			let ad = this.$store.getters['ads/findInViewCache'](id);

			return ad;
		},
		currentTags: function () {
			if (!this.ad) {
				return [];
			}

			return this.ad.tags || [];
		},
	},

	props: {
		adId: {},
	},

	methods: {
		onInput: async function (e) {
			this.processing = true;

			let form = {
				id: this.adId,
				tags: e,
			};

			try {
				let res = await this.$store.dispatch('ads/update', form);
				if (res) {
					await this.$store.dispatch('ads/updateViewCacheAd', res);
					this.$emit('onEdit', res);
				}
			} catch (err) {
				console.log(err);
				this.$store.dispatch('message/onServerError');
			}

			this.processing = false;
		},
	},
};
</script>

<style lang="scss">
.c-tag-selector {
	margin-top: var(--margin);
	padding: 0 4px;

	.c-input-tags {
		padding-bottom: 0;
	}

	.vue-tags-input {
		margin-bottom: 0;

		.ti-input {
			min-height: initial;
		}

		.ti-tags {
			//border: none;
			//padding: 0;

			.ti-new-tag-input-wrapper {
				input {
					padding: 2px 8px;
				}
			}
		}

		.ti-tag {
			padding: 2px 8px;
			margin-bottom: 4px;
		}
	}

	&__status {
		display: flex;
		align-items: center;
		font-size: var(--font-size-xs);
		opacity: 0.8;
		padding: var(--margin-sm) 0;
		user-select: none;

		.c-spinner {
			display: block;
			width: 14px;
			height: 14px;
			min-width: 14px;
			min-height: 14px;
			margin-left: 0;
			border-color: var(--color-primary);
			border-bottom-color: transparent;
			margin-right: var(--margin);
		}
	}
}
</style>
