import api from '@swipekit/lib/api.js';

import CrudStore from '@swipekit/lib/crud-store';

import { parseISO, differenceInDays } from 'date-fns';

let crud = new CrudStore({
	name: 'workspaces',
	singular: 'workspace',
});

const state = {
	...crud.state,
	isSingleton: true,
};
const getters = {
	...crud.getters,

	isAdmin: function (state, getters, rootState, rootGetters) {
		let workspace = state.resource;
		if (!workspace) {
			return false;
		}
		let user = rootGetters['users/user'];

		if (!user) {
			return false;
		}

		if (user.id !== workspace.adminId) {
			return false;
		}

		return true;
	},

	subscriptionEndsIn: function (state, getters, rootState, rootGetters) {
		let workspace = state.resource;

		if (!workspace.subscriptionEndsAt) {
			return;
		}

		const date1 = parseISO(workspace.subscriptionEndsAt);

		const date2 = new Date();

		const diff = differenceInDays(date1, date2);

		return diff;
	},

	admin: function (state) {
		let workspace = state.resource;

		let temp = null;
		for (let i = 0; i < workspace.users.length; i++) {
			let u = workspace.users[i];
			if (u.id === workspace.adminId) {
				temp = u;
				break;
			}
		}
		return temp;
	},
};
const actions = {
	...crud.actions,

	async getLocal(store) {
		let resources = [];
		try {
			resources = await crud.api[crud.resourceName].get('local');
		} catch (err) {
			throw err;
		}

		store.commit('SET', resources);
		return resources;
	},

	async doAction(store, obj) {
		let action = obj.action;
		let id = obj.id;
		let response = await crud.api.api.doAction(id, action);

		return response;
	},

	async fetchAndStore(store) {
		let resources = (await crud.api[crud.resourceName].fetchAndStore()) || [];

		store.commit(resources);
	},

	async invite(store, form) {
		let workspace = await api.workspaces.invite(form).catch((err) => {
			throw err;
		});

		if (workspace) {
			store.commit('SET', workspace);
		}

		return workspace;
	},

	async removeUser(store, form) {
		let workspace = await api.workspaces.removeUser(form);

		if (workspace) {
			store.commit('SET', workspace);
		}
	},

	async resendVerification(store) {
		let payload = {
			id: store.getters.all.id,
		};

		let response = await api.workspaces.resendVerification(payload).catch((err) => {
			throw err;
		});

		return response;
	},

	async verifyToken(store, token) {
		let payload = {
			id: store.getters.all.id,
			token: token,
		};

		let response = await api.workspaces.verifyToken(payload).catch((err) => {
			throw err;
		});

		if (response) {
			store.commit('SET', response);
		}

		return response;
	},

	consumePie: async (store, pie) => {
		if (pie.workspace) {
			let workspace = {
				...pie.workspace,
			};

			delete workspace.boards;
			delete workspace.ads;
			store.commit('SET', workspace);
		}
	},

	async afterLogin(store, pie) {
		let mode = store.rootState.config.mode;

		if (store.rootGetters['users/isAuth']) {
			if (mode === 'EXTENSION') {
				await store.dispatch('getLocal');
			} else {
				if (!pie) {
					await store.dispatch('get');
				} else {
					await store.dispatch('consumePie', pie);
				}
			}
		}
	},

	async cancelSubscription(store, form) {
		let currentWorkspace = store.getters['all'];

		let workspace = await api.workspaces.cancelSubscription(currentWorkspace.id, form);

		if (workspace && typeof workspace === 'object') {
			store.commit('SET', workspace);

			return true;
		} else {
			return false;
		}
	},
};

const mutations = {
	...crud.mutations,
};

let store = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

export default store;
