<template>
	<div class="c-modal-board-update">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<template v-slot:title>
				<h2>Share this ad</h2>
				<p class="article-text">Share this Ad with others, or embed this Ad inside Notion, Clickup, Asana or in any webpage.</p>
			</template>
			<template>
				<AdShare :ad="ad"></AdShare>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';
import AdShare from '@swipekit/components/app/ad-share';

import api from '@swipekit/lib/api';

export default {
	components: {
		Modal,
		AdShare,
	},

	data: function () {
		return {
			lock: false,
			options: {
				name: 'modal-share',
				active: false,
			},
		};
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		shareId: {
			handler: function () {
				this.$nextTick(() => {
					if (this.shareId) {
						this.options.active = true;
						this.lock = true;
						setTimeout(() => {
							this.lock = false;
						}, 500);
					} else {
						this.options.active = false;
					}
				});
			},
			immediate: true,
		},
	},

	computed: {
		ad: function () {
			if (this.shareId) {
				return this.shareAd;
			} else {
				return null;
			}
		},
		shareId: function () {
			return this.$store.getters['app/shareAdId'];
		},
		shareAd: function () {
			return this.$store.getters['app/shareAd'];
		},
	},

	methods: {
		closeModal: function () {
			if (this.lock) {
				return false;
			}
			this.$store.dispatch('app/setAdShare', null);
		},
		onSubmit: function () {
			this.$store.dispatch('triggerExtension', { type: 'boards:update' });
			this.options.active = false;
		},
		activate: function () {
			this.options.active = true;
		},
	},

	mounted: function () {
		//this.options.active = true;
	},
};
</script>

<style lang="scss"></style>
