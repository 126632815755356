<template>
	<div class="c-input-boards-dropdown">
		<label v-if="label" class="form__label" :for="slug">{{ label }}</label>
		<div tabindex="1" class="c-input-boards-dropdown__input" @click="onInputClick($event)" ref="popupBoard">
			<button type="button" class="btn btn-primary" @click="onTagClick($event, board)" v-for="board in computedCurrentBoards" :key="board.id">
				<span>
					{{ board.name }}
				</span>
				<span class="close"> </span>
			</button>
		</div>
		<PopupBoard placement="bottom" v-if="hasMounted" :active="active" :target="target" @onClose="active = false">
			<InputBoards v-model="currentBoards"></InputBoards>
		</PopupBoard>
		<InputError v-if="hasError" :message="errorMessage"></InputError>
		<InputDescription v-if="description" :message="description"></InputDescription>
	</div>
</template>

<script>
import InputBoards from './input-boards';
import PopupBoard from '@swipekit/components/popup/popup-board';
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@swipekit/lib/slugify.js';

export default {
	components: {
		PopupBoard,
		InputBoards,
		InputError,
		InputDescription,
	},

	data: function () {
		return {
			hasMounted: false,
			currentBoards: [],
			active: false,
		};
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		validation: {},
		value: {},
		placeholder: {
			type: String,
			default: '',
		},
		noStyle: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: '',
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		errorMessage: {},
	},

	watch: {
		value: function () {
			let valueArr = this.value || [];

			let currentArr = this.currentBoards || [];

			if (valueArr.length !== currentArr.length) {
				this.currentBoards = this.value;
			}
		},
		currentBoards: function (newz, old) {
			this.onChange({
				target: {
					value: this.currentBoards,
				},
			});
		},
	},

	computed: {
		slug: function () {
			return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
		},
		computedCurrentBoards: function () {
			let boards = JSON.parse(JSON.stringify(this.currentBoards));

			for (let i = 0; i < boards.length; i++) {
				boards[i] = this.$store.getters['boards/one'](boards[i]);
			}

			return boards;
		},
		target: function () {
			return this.$refs.popupBoard;
		},
	},

	methods: {
		removeTag: function (boardId) {
			let i = this.currentBoards.indexOf(boardId);

			if (i === -1) {
				return;
			}

			this.currentBoards.splice(i, 1);
		},
		onInputClick: function (e) {
			if (e.target.closest('.c-input-boards-dropdown__input .btn')) {
				return;
			}

			this.active = !this.active;
		},
		onTagClick: function (e, board) {
			e.preventDefault();
			if (e.target.classList.contains('close')) {
				this.removeTag(board.id);
			}
		},
		onChange: function (e) {
			let value = e.target.value;
			this.$emit('input', value);
		},
	},

	created: function () {
		let value = this.value;

		if (value && value.length > 0) {
			let temp = value.map((board) => {
				return board.boardId;
			});
			this.currentBoards = temp;
		}
	},

	mounted: function () {
		this.hasMounted = true;
	},
};
</script>

<style lang="scss">
.c-input-boards-dropdown {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: var(--margin-lg);
	width: 100%;

	&__input {
		min-height: 50px;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		line-height: 1em;
		padding: var(--margin);
		border-radius: var(--border-radius);
		border: var(--color-input-background) solid 1px;
		background-color: var(--color-bg-1);
		list-style: none;
		box-sizing: border-box;
		cursor: pointer;

		> .btn {
			margin: 2px;
			cursor: pointer;
			padding: var(--margin) var(--spacer-sm);
			padding-right: var(--margin-lg);
			background-color: var(--color-button-primary-light);
			border-radius: var(--border-radius-full);
			display: flex;
			justify-content: center;
			align-items: center;
			letter-spacing: 0.3px;
			font-size: var(--font-size-sm);
		}

		&:focus,
		&:active {
			border-color: var(--color-input-background-light);
			@include focus();
		}
	}

	.close {
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		&:after {
			content: '\e901';
		}
	}

	.c-input-boards {
		padding-bottom: 0;
	}
}
</style>
