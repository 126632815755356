<template>
	<div class="c-popup-board">
		<Popup :placement="placement" :active="active" :target="target" @onClose="onClose">
			<slot></slot>
		</Popup>
	</div>
</template>

<script>
import Popup from './index';

export default {
	components: {
		Popup,
	},

	data: function () {
		return {
			html: '',
		};
	},

	props: {
		id: {},
		active: {
			type: Boolean,
			default: false,
		},
		target: {},
		placement: {
			type: String,
			default: 'top-left',
		},
	},

	watch: {
		active: function () {
			if (this.active) {
				let input = this.$el.querySelector('input');
				if (input) {
					input.focus();
				}
			}
		},
		block: function () {
			if (this.block) {
				//this.html = this.block.content.note || "";
				//this.active = false;
				this.$nextTick(() => {
					//this.active = true;
				});
			} else {
				this.html = '';
			}
		},
		html: function () {
			this.updateBlock();
		},
	},

	computed: {
		block: function () {
			if (!this.id) {
				return null;
			}
			let block = this.$store.getters['blocks/one'](this.id);
			return block;
		},
		boards: function () {
			return this.$store.getters['boards/filtered'];
		},
	},

	methods: {
		activate: function (id) {
			this.html = '';
			let block = this.$store.getters['blocks/one'](id);
			if (!block) {
				return;
			}
			let selector = `.c-tile[data-block-id="${id}"]`;
			let el = document.querySelector(selector);
			if (!el) {
				return;
			}
			this.block = block;
			this.target = el;
		},
		onClose: function () {
			this.$emit('onClose');
		},
	},
};
</script>

<style lang="scss">
.c-popup-board {
	> .c-popup {
		left: 0 !important;
		width: 100%;
		background-color: var(--color-bg-1);
	}

	.c-popup__arrow {
		display: none;
	}

	&__inner {
		padding: var(--margin-lg) 0;

		a {
			display: flex;
			padding: var(--margin) var(--margin-lg);

			&:hover,
			&:active {
				background-color: var(--color-primary);
				color: #fff;
			}
		}
	}
	&__close {
		position: absolute;
		top: 12px;
		right: var(--margin-lg);
		opacity: 0.75;
		cursor: pointer;

		&:hover,
		&:active {
			opacity: 1;
		}
	}

	.form-control {
		background-color: var(--color-bg-1);
	}

	// .c-popup__arrow {
	//   bottom: -12px;
	//   color: var(--color-bg-5);
	//   transform: rotate(180deg) translateX(-50%) !important;
	// }
}
</style>
