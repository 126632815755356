<template>
	<main class="swipekit-button-new" :data-selection="computedSelection">
		<div class="c-picker">
			<div :class="['c-picker__left', { active: selected.length > 0 }]" @click="toggleList">
				<span>{{ boardText }}</span>
			</div>
			<div class="c-picker__right" @click="addBoard">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V11.25H8C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H11.25V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H12.75V8Z"
						fill="currentColor"
					/>
				</svg>
			</div>
			<List ref="list" @clickOutside="closeList" @onCreateBoard="onCreateBoard" :baseUrl="baseurl" :boards="boards" :selected="selected" @change="onChange" :active="listActive"></List>
			<CreateBoard @closeCreateBoard="onCloseCreateBoard" @createBoard="onBoardCreated" :baseUrl="baseurl" :active="createBoard"></CreateBoard>
		</div>
		<button :class="[{ active: saving === true }, { error: !!errorMessage }, { saved: saved }]" @click="onClick">
			{{ buttonText }}
		</button>
	</main>
</template>

<script>
import List from './list.vue';
//import utils from "@/lib/utils";
import CreateBoard from './create-board';
import text, { getText } from '@swipekit/lib/text';

const utils = {};

export default {
	components: {
		List,
		CreateBoard,
	},

	props: {
		blockId: {},
		token: {
			type: String,
			default: '',
		},
		baseurl: {
			type: String,
			default: '',
		},
		fbsaved: {},
		error: {},
		type: {},
		data: {},
	},

	data: function () {
		return {
			saved: false,
			saving: false,
			errorMessage: '',
			listActive: false,
			createBoard: false,
			boards: [],
			selected: [],
		};
	},

	watch: {
		createBoard: function () {
			if (this.createBoard) {
				this.listActive = false;

				this.$nextTick(() => {
					this.$el.querySelector('form input[type="text"]').focus();
				});
			}
		},
		error: {
			handler: function () {
				if (this.error) {
					this.errorMessage = this.error;
				}
			},
			immediate: true,
		},
		fbsaved: {
			handler: function () {
				if (this.fbsaved) {
					this.saved = true;
				}
			},
			immediate: true,
		},
	},

	computed: {
		buttonText: function () {
			if (this.errorMessage) {
				return this.errorMessage;
			}

			if (this.saving) {
				return `Saving...`;
			}

			if (this.saved) {
				return `Saved`;
			}

			return `Save`;
		},
		computedSelection: function () {
			return JSON.stringify(this.selected);
		},
		boardText: function () {
			if (this.selected && this.selected.length > 0) {
				let len = this.selected.length;
				let type = '';
				if (len > 1) {
					type = 's';
				}
				return `${len} board${type} selected`;
			}
			return `Select boards`;
		},
	},

	methods: {
		onCloseCreateBoard: function () {
			this.createBoard = false;
		},
		onBoardCreated: function () {
			this.createBoard = false;
			this.listActive = true;

			this.$nextTick(() => {
				this.$refs.list.refresh();
			});
		},
		onCreateBoard: function () {
			this.listActive = false;
			this.createBoard = true;
		},
		onClick: async function () {
			this.listActive = false;
			this.saving = true;

			let obj = {
				blockId: this.blockId,
				boards: this.selected || [],
			};

			let response = await this.$store.dispatch('ads/add', obj).catch((err) => {
				//console.log(err);
			});

			let saved = false;

			if (response && response.status === 201) {
				saved = true;
			}

			setTimeout(() => {
				this.saving = false;
				this.saved = true;

				let errorText = getText('error');

				if (response && response.status === 401) {
					errorText = getText('errorAuth');
				}
				if (response && response.status === 403) {
					errorText = getText('errorPay');
				}
				if (response && response.status === 405) {
					errorText = getText('errorEmailVerificationPending');
				}

				if (saved) {
					this.saved = true;
				} else {
					this.errorMessage = errorText;
				}
			}, 100);
		},
		onChange: function (selected) {
			this.selected = selected;
		},
		toggleList: function () {
			setTimeout(() => {
				this.listActive = !this.listActive;
			}, 1);
		},
		closeList() {
			this.listActive = false;
		},

		addBoard: function () {
			this.$store.dispatch('boards/setAdd', 1);
			//this.createBoard = true;
		},
		handleKeyPress(event) {
			if (event.key === 'Escape' || event.key === 'Esc') {
				this.listActive = false;
				this.createBoard = false;
			}
		},
	},

	mounted: function () {
		document.body.addEventListener('keydown', this.handleKeyPress);
	},
	beforeDestroy() {
		document.body.removeEventListener('keydown', this.handleKeyPress);
	},
};
</script>

<style lang="scss">
.c-picker {
	--p: 245;
	--font-family-heading: 'Monument Extended';

	position: relative;
	margin-bottom: 8px;
	display: grid;
	grid-template-columns: 1fr 36px;
	width: 100%;
	height: 35.5px;
	border-radius: 6px;
	border: #dfdfdf solid 1px;
	text-align: center;

	&__left {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		user-select: none;
		cursor: pointer;
		background-color: var(--color-bg-1);
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;

		&.active {
			color: var(--color-button-primary);
		}
	}

	&__right {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-left: #dfdfdf solid 1px;
		background-color: var(--color-bg-1);
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		cursor: pointer;

		svg {
			color: var(--color-button-primary);
		}
	}
}
.c-picker-list {
	width: 100%;
	position: absolute;
	z-index: 101;
	top: 40px;
	left: 0;
	height: 329.12px;
	overflow-y: auto;
	border-radius: 12px;
	border: var(--color-bg-5) solid 1px;
	background-color: #fff;
	box-shadow: rgba(23, 23, 23, 0.1) 0 2px 12px;
	transform: translateY(-20px);
	opacity: 0;
	transition: all 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
	pointer-events: none;
}
.c-picker-list.active {
	transform: translateY(0);
	opacity: 1;
	pointer-events: initial;
}
.c-picker-list .c-picker-list__item {
	display: grid;
	grid-template-columns: 1fr; // calc(100% - 48px) 48px;
	align-items: center;

	&.active {
		.c-picker-list__clickable {
			article > p,
			article > span {
				color: #fff;
			}
		}
	}
}
.c-picker-list .c-picker-list__item:focus {
	outline: none;
	.c-picker-list__clickable {
		box-shadow: 0 0 0 3px hsla(var(--p), 100%, 60%, 0.5) !important;
	}
}
.c-picker-list__input {
	padding: var(--margin);

	.c-input-text {
		padding-bottom: 0;
	}
}
.c-picker-list__input input {
	appearance: none;
	display: block;
	border: none;
	width: 100%;
	height: 100%;
	padding: 4px 8px;
	padding-right: 32px;
	background-color: transparent;
	border-radius: 6px;
	background-color: var(--color-bg-3);
}
.c-picker-list .c-picker-list__item .c-picker-list__clickable {
	display: grid;
	grid-template-columns: calc(100% - 32px) 32px;
	align-items: center;
	padding: 4px 8px;
	font-size: 14px;
	text-align: center;
	text-align: left;
	cursor: pointer;
	user-select: none;
	transition: all 80ms linear;
}
.c-picker-list .c-picker-list__item:not(:last-child) {
	//margin-bottom: 4px;
}
.c-picker-list .c-picker-list__default {
	font-size: 13px;
	background-color: hsl(80, 95%, 95%);
	height: 100%;
	display: flex;
	align-items: center;
	text-align: left;
	border-radius: 8px;
}
.c-picker-list .c-picker-list__item.active .c-picker-list__clickable {
	background-color: var(--color-button-primary-light);
	color: #fff;
}
.c-picker-list .c-picker-list__item .c-picker-list__clickable:hover {
	background-color: var(--color-button-primary-light);

	article > p,
	article > span {
		color: #fff;
	}
}
.c-picker-list .c-picker-list__item .c-picker-list__clickable > article > p {
	max-width: calc(100% - 32px);
	margin-top: 0;
	margin-bottom: 0;
	margin-bottom: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.2;
	//color: rgba(0, 0, 0, 0.8);
}
.c-picker-list .c-picker-list__item .c-picker-list__clickable > article > span {
	max-width: calc(100% - 32px);
	font-size: 12px;
	color: rgba(0, 0, 0, 0.8);
	opacity: 0.75;
}
.c-picker-list .c-picker-list__item .c-picker-list__clickable > svg {
	display: none;
}
.c-picker-list .c-picker-list__item.active .c-picker-list__clickable > svg {
	display: block;
}
.swipekit-button-new {
	padding: var(--margin);
	background-color: var(--color-bg-3);
	border-bottom-left-radius: var(--item-border-radius);
	border-bottom-right-radius: var(--item-border-radius);
}
.swipekit-button-new > button {
	position: relative;
	-webkit-appearance: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 33.5px;
	text-shadow: var(--text-shadow);
	background-color: var(--color-button-primary);
	color: var(--color-button-font);
	font-weight: 600;
	font-size: 13px;
	border: none;
	text-align: center;
	padding: 4px 10px;
	border-radius: 6px;
	transition: all 80ms linear;
	font-family: var(--font-family-heading);
	text-transform: uppercase;
	font-style: italic;
	user-select: none;
	cursor: pointer;
	line-height: 1.2;
	box-shadow: inset 0 1px 1px 0px rgba(255, 255, 255, 0.25), inset 0 -1px 4px 1px rgba(0, 0, 0, 0.15), 0 1px 8px 1px rgba(0, 0, 0, 0.075);
}
.swipekit-button-new > button:hover {
	color: var(--color-button-font);
	background-color: var(--color-button-primary) !important;
	cursor: pointer;
}
.swipekit-button-new > button:active {
	box-shadow: inset 0 -1px 1px 0px rgba(255, 255, 255, 0.2), inset 0 1px 6px 1px rgba(0, 0, 0, 0.15), 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
}
.swipekit-button-new > button.active {
	color: var(--color-button-font);
	background-color: var(--color-button-primary) !important;
	cursor: not-allowed;
	pointer-events: none;
}
.swipekit-button-new > button.active:after {
	content: '';
	position: absolute;
	top: 11 px;
	left: 10px;
	width: 16px;
	height: 16px;
	border: 2px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
.swipekit-button-new > button.saved {
	background-color: var(--color-neutral);
	pointer-events: none;
	box-shadow: none;
}
.swipekit-button-new > button.saved:hover {
	background-color: var(--color-neutral);
}
.swipekit-button-new > button.error {
	background-color: var(--color-danger);
	pointer-events: none;
	font-size: 11px;
	font-style: initial;
}
.swipekit-button-new > button.error:hover {
	background-color: var(--color-danger);
}
.c-picker-create-board {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border-radius: 6px;
	transition: all 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
	user-select: none;

	opacity: 0;
	pointer-events: none;
}
.c-picker-create-board.active {
	opacity: 1;
	pointer-events: initial;
}
.c-picker-create-board form {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	display: grid;
	grid-template-columns: 1fr 36px;
}
.c-picker-create-board form span {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-left: #dfdfdf solid 1px;
}
.c-picker-create-board form > svg {
	position: absolute;
	top: 4.5px;
	right: 42px;
	color: var(--color-danger);
	cursor: pointer;
}
.c-picker-create-board form span > button {
	padding: 4px;
	position: relative;
	-webkit-appearance: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: var(--color-button-primary);
	color: #fff;
	width: 21.5px;
	height: 21.5px;
	border-radius: 99px;
}

input {
	-webkit-appearance: none;
	appearance: none;
	display: block;
	border: none;
	width: calc(100% - 3px);
	height: 100%;
	padding: 4px 8px;
	padding-right: 32px;
	background-color: transparent;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
input:focus {
	outline: none;
	box-shadow: 0 0 0 3px hsla(var(--p), 100%, 60%, 0.5);
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
