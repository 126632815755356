<template>
	<div v-tooltip="`This Ad is ${statusText}`" :class="['c-active-status', { active: active }]" v-if="hasStatus"></div>
</template>

<script>
export default {
	props: {
		block: {},
	},

	computed: {
		statusText: function () {
			let status = this.block.status;

			if (status) {
				status = status.toLowerCase();
				status = status.charAt(0).toUpperCase() + status.slice(1);

				return status;
			}

			return '';
		},
		active: function () {
			let b = this.block;

			if (b.status === 'ACTIVE') {
				return true;
			}

			return false;
		},
		hasStatus: function () {
			let b = this.block;

			if (b.status) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style lang="scss">
.c-active-status {
	position: absolute;
	z-index: 1;
	bottom: -3px;
	left: -3px;
	width: 12px;
	height: 12px;
	border-radius: var(--border-radius-full);
	background-color: rgb(133, 133, 133);

	border: var(--color-button-font) solid 1px;

	&.active {
		background: rgb(130, 199, 27);
	}
}
</style>
