<template>
	<div class="c-item-metadata">
		<div class="c-item-metadata__inner">
			<span class="c-grid-item__label" v-if="labels"> 4 </span>
			<p v-if="'adActiveDays' in stat && typeof stat.adActiveDays === 'number'" title="Ad Active days">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2 12H6L9 3L15 21L18 12H22" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>

				<span> {{ stat.adActiveDays }} days - {{ stat.adActiveStatus }} </span>
			</p>
			<p v-if="stat.adSpend" title="Ad Spend(EU only)">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 7.5V6.5M12 16.5V17.5M14.1655 9C13.7333 8.4022 12.9254 8 12 8H11.7222C10.4949 8 9.5 8.79594 9.5 9.77778V9.8541C9.5 10.5564 9.99598 11.1984 10.7812 11.5125L13.2188 12.4875C14.004 12.8016 14.5 13.4436 14.5 14.1459C14.5 15.1699 13.4624 16 12.1824 16H12C11.0746 16 10.2667 15.5978 9.83446 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>

				<span> ${{ format(stat.adSpend) }} </span>
			</p>
			<p v-if="stat.adReach" title="Ad Reach(EU only)">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16 7H21V12M20.5 7.5L13 15L9 11L3 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
				<span> {{ format(stat.adReach) }} </span>
			</p>
			<p v-if="stat.likes || stat.likes === 0" title="Likes">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M21 10C21 15.75 12.75 20 12 20C11.25 20 3 15.75 3 10C3 6 5.5 4 8 4C10.5 4 12 5.5 12 5.5C12 5.5 13.5 4 16 4C18.5 4 21 6 21 10Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linejoin="round"
					/>
				</svg>
				<span> {{ format(stat.likes) }} </span>
			</p>
			<p v-if="stat.comments || stat.comments === 0" title="Comments">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.4876 3.36093 14.891 4 16.1272L3 21L8 20.0645C9.20496 20.6633 10.5632 21 12 21Z"
						stroke="black"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>

				<span> {{ format(stat.comments) }} </span>
			</p>
			<p v-if="stat.shares || stat.shares === 0" title="Shares">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M14.3354 7.37976C14.8349 8.34235 15.8406 9 17 9C18.6569 9 20 7.65685 20 6C20 4.34315 18.6569 3 17 3C15.3431 3 14 4.34315 14 6C14 6.49749 14.1211 6.9667 14.3354 7.37976ZM14.3354 7.37976L8.66458 10.6202M8.66458 10.6202C8.16512 9.65765 7.15936 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15C7.15936 15 8.16512 14.3424 8.66458 13.3798M8.66458 10.6202C8.8789 11.0333 9 11.5025 9 12C9 12.4975 8.8789 12.9667 8.66458 13.3798M8.66458 13.3798L14.3354 16.6202M14.3354 16.6202C14.1211 17.0333 14 17.5025 14 18C14 19.6569 15.3431 21 17 21C18.6569 21 20 19.6569 20 18C20 16.3431 18.6569 15 17 15C15.8406 15 14.8349 15.6576 14.3354 16.6202Z"
						stroke="black"
						stroke-width="2"
					/>
				</svg>

				<span> {{ format(stat.shares) }} </span>
			</p>
			<p v-if="stat.views" title="Views">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9 5L9 19M3.00439 11L3 19M21 8L21 19M14.9978 14L14.9978 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>

				<span> {{ format(stat.views) }} </span>
			</p>
			<!-- <p title="Asset type">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3 3V2C2.44772 2 2 2.44772 2 3H3ZM15 3H16C16 2.44772 15.5523 2 15 2V3ZM14 9V10H16V9H14ZM3 15H2C2 15.5523 2.44772 16 3 16V15ZM9 16H10V14H9V16ZM9 9V8C8.44772 8 8 8.44772 8 9H9ZM21 9H22C22 8.44772 21.5523 8 21 8V9ZM9 21H8C8 21.5523 8.44772 22 9 22V21ZM21 21V22C21.5523 22 22 21.5523 22 21H21ZM2.4453 11.1679C1.98577 11.4743 1.8616 12.0952 2.16795 12.5547C2.4743 13.0142 3.09517 13.1384 3.5547 12.8321L2.4453 11.1679ZM6 10L6.5547 9.16795C6.2188 8.94402 5.7812 8.94402 5.4453 9.16795L6 10ZM8.4453 12.8321C8.90483 13.1384 9.5257 13.0142 9.83205 12.5547C10.1384 12.0952 10.0142 11.4743 9.5547 11.1679L8.4453 12.8321ZM6.25 5.25C5.69772 5.25 5.25 5.69772 5.25 6.25C5.25 6.80228 5.69772 7.25 6.25 7.25V5.25ZM6.26 7.25C6.81228 7.25 7.26 6.80228 7.26 6.25C7.26 5.69772 6.81228 5.25 6.26 5.25V7.25ZM14 13.4L14.5795 12.585C14.2746 12.3682 13.874 12.3397 13.5415 12.5113C13.2089 12.6829 13 13.0258 13 13.4H14ZM14 16.6H13C13 16.9742 13.2089 17.3171 13.5415 17.4887C13.874 17.6603 14.2746 17.6318 14.5795 17.415L14 16.6ZM16.25 15L16.8295 15.815C17.0933 15.6274 17.25 15.3237 17.25 15C17.25 14.6763 17.0933 14.3726 16.8295 14.185L16.25 15ZM3 4H15V2H3V4ZM14 3V9H16V3H14ZM2 3V15H4V3H2ZM3 16H9V14H3V16ZM9 10H21V8H9V10ZM8 9V21H10V9H8ZM9 22H21V20H9V22ZM20 9V21H22V9H20ZM3.5547 12.8321L6.5547 10.8321L5.4453 9.16795L2.4453 11.1679L3.5547 12.8321ZM5.4453 10.8321L8.4453 12.8321L9.5547 11.1679L6.5547 9.16795L5.4453 10.8321ZM5.5 6.25C5.5 5.83579 5.83579 5.5 6.25 5.5V7.5C6.94036 7.5 7.5 6.94036 7.5 6.25H5.5ZM6.25 5.5C6.66421 5.5 7 5.83579 7 6.25H5C5 6.94036 5.55964 7.5 6.25 7.5V5.5ZM7 6.25C7 6.66421 6.66421 7 6.25 7V5C5.55964 5 5 5.55964 5 6.25H7ZM6.25 7C5.83579 7 5.5 6.66421 5.5 6.25H7.5C7.5 5.55964 6.94036 5 6.25 5V7ZM6.25 7.25H6.26V5.25H6.25V7.25ZM13 13.4V16.6H15V13.4H13ZM14.5795 17.415L16.8295 15.815L15.6705 14.185L13.4205 15.785L14.5795 17.415ZM16.8295 14.185L14.5795 12.585L13.4205 14.215L15.6705 15.815L16.8295 14.185Z"
						fill="currentColor"
					/>
				</svg>
				<span> {{ stat.assetType }} </span>
			</p> -->
			<!-- <p title="When was this Ad saved">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11 20H4V9M4 9V5H20V9M4 9H20M20 9V11M8 5V3M16 5V3M18 15V18M18 18V21M18 18H15M18 18H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
				<span>
					{{ stat.savedOn }}
				</span>
			</p> -->
			<!-- <p v-if="ad.block.screenshotId" title="Landing page Screenshots">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H5V8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8V4ZM15 4C15 3.44772 15.4477 3 16 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 20.5523 9 20 9C19.4477 9 19 8.55228 19 8V5H16C15.4477 5 15 4.55228 15 4ZM4 15C4.55228 15 5 15.4477 5 16V19H8C8.55228 19 9 19.4477 9 20C9 20.5523 8.55228 21 8 21H4C3.44772 21 3 20.5523 3 20V16C3 15.4477 3.44772 15 4 15ZM20 15C20.5523 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 20.5523 15 20C15 19.4477 15.4477 19 16 19H19V16C19 15.4477 19.4477 15 20 15Z"
						fill="currentColor"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M10.2929 7.29289C10.4804 7.10536 10.7348 7 11 7H13C13.2652 7 13.5196 7.10536 13.7071 7.29289L14.4142 8H16C16.5523 8 17 8.44772 17 9V15C17 15.5523 16.5523 16 16 16H8C7.44772 16 7 15.5523 7 15V9C7 8.44772 7.44772 8 8 8H9.58579L10.2929 7.29289ZM10.5 11.85C10.5 11.0216 11.1716 10.35 12 10.35C12.8284 10.35 13.5 11.0216 13.5 11.85C13.5 12.6784 12.8284 13.35 12 13.35C11.1716 13.35 10.5 12.6784 10.5 11.85Z"
						fill="currentColor"
					/>
				</svg>
				<span> LP Screenshots available </span>
			</p> -->
			<!-- <p v-if="ad.block.transcriptId" title="Video transcript">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M15 10H9M12 14H9M3 20H16C18.7614 20 21 17.7614 21 15V9C21 6.23858 18.7614 4 16 4H8C5.23858 4 3 6.23858 3 9V20Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>

				<span> Transcript available </span>
			</p> -->
			<!-- <p v-if="ad.block.categories && ad.block.categories.length" title="Ad Categories(niches)">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.4 7.5H7.6M3 4V11.5858C3 11.851 3.10536 12.1054 3.29289 12.2929L11.7929 20.7929C12.1834 21.1834 12.8166 21.1834 13.2071 20.7929L20.7929 13.2071C21.1834 12.8166 21.1834 12.1834 20.7929 11.7929L12.2929 3.29289C12.1054 3.10536 11.851 3 11.5858 3H4C3.44772 3 3 3.44772 3 4ZM8 7.5C8 7.77614 7.77614 8 7.5 8C7.22386 8 7 7.77614 7 7.5C7 7.22386 7.22386 7 7.5 7C7.77614 7 8 7.22386 8 7.5Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="square"
					/>
				</svg>

				<span> {{ ad.block.categories.join(', ') }} </span>
			</p> -->

			<section v-if="adLibraryLink || landingPage">
				<a v-if="adLibraryLink" :href="adLibraryLink" target="_blank" class="click-prevent btn btn-box btn-sm">Ad Library</a>
				<a v-if="landingPage" :href="landingPage" target="_blank" class="click-prevent btn btn-box btn-sm">Landing page</a>
			</section>
		</div>
	</div>
</template>

<script>
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import stats from '@swipekit/lib/stats';

export default {
	components: {
		Date,
	},

	props: {
		labels: {
			type: Boolean,
			default: false,
		},
		ad: {},
	},

	computed: {
		adLibraryLink: function () {
			let block = this.ad.block;
			if (block.type === 'facebook') {
				let str = `https://www.facebook.com/ads/library/?id=${block.metaId}`;
				let meta = block.meta;
				if (!meta) {
					return '';
				}
				if (meta.pageId) {
					str = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${meta.pageId}&search_type=page&media_type=all`;
				}

				return str;
			}
			if (block.type === 'tiktok-library') {
				return block.source;
			}
			if (block.type === 'tiktok-top-ads') {
				return block.source;
			}
		},
		landingPage: function () {
			let block = this.ad.block;
			let ctaLink = block.ctaLink;
			if (ctaLink) {
				return ctaLink;
			}

			if (block.meta && block.meta.ctaLink) {
				return block.meta.ctaLink;
			}

			return false;
		},
		stat: function () {
			let block = this.ad.block;
			let s = stats(block);

			return s;
		},
		date: function () {
			let date = parseISO(this.ad.createdAt);

			let str = format(date, 'mm dd');

			return str;
		},
	},

	methods: {
		format: function (num) {
			let newNum = parseInt(num);
			if (isNaN(newNum)) {
				return num;
			}

			return num.toLocaleString('en-US');
		},
		onComment: function () {
			this.$emit('onComment');
		},
	},
};
</script>

<style lang="scss">
.c-item-metadata {
	position: relative;
	display: block;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: var(--color-bg-4);
	}

	&__inner {
		padding: var(--margin);
		background-color: hsl(var(--s2), 60%, 97.5%);
		display: flex;
		flex-direction: column;

		> p {
			margin-bottom: var(--margin-sm);
			display: flex;
			align-items: center;
			font-size: var(--font-size-xs);
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';

			> svg {
				margin-right: var(--margin);
				color: #000;
				opacity: 0.5;
			}

			> span {
				font-weight: 450;
			}

			// &:last-child {
			//   margin-bottom: 0;
			// }
		}

		> section {
			display: flex;
			flex-direction: row;

			.btn {
				background-color: hsl(var(--s), 16%, 91%);
				margin-top: var(--margin);
				font-size: var(--font-size-xs);
				font-weight: 500;
				letter-spacing: 0;
				margin-right: var(--margin);
				padding: var(--margin-sm) 12px;
				box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.6);

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
</style>
