<template>
	<div class="c-item-tags" v-if="tags">
		<span v-for="(tag, i) in tags" :key="i">
			{{ tag }}
		</span>
	</div>
</template>

<script>
export default {
	props: {
		ad: {},
	},

	computed: {
		tags: function () {
			let ad = this.ad;

			if (!ad.tags) {
				return null;
			}
			return ad.tags || [];
		},
	},
};
</script>

<style lang="scss">
.c-item-tags {
	padding: 0 var(--margin) 0 var(--margin);

	> span {
		display: inline-block;
		position: relative;
		margin-right: 4px;
		margin-bottom: 4px;
		background-color: var(--color-bg-3);
		padding: 4px 6px;
		padding-right: 14px;
		font-size: var(--font-size-xs);
		line-height: 1;
		border-radius: var(--border-radius);
		user-select: none;
		//cursor: pointer;
		transition: all var(--transition-time-sm) ease-out;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 2px);
			right: 6px;
			width: 4px;
			height: 4px;
			background-color: currentColor;
			border-radius: 2px;
		}

		// &:hover,
		// &:focus,
		// &.active {
		// 	outline: none;
		// 	background-color: var(--color-primary);
		// 	color: var(--color-button-font);
		// }
	}
}
</style>
