<template>
	<div :class="['c-popup', { active: currentlyActive === true }]" ref="popup">
		<div class="c-popup__arrow" data-popper-arrow>
			<svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.99976 4.00066C3.99976 12.0003 -0.000244141 12.0005 -0.000244141 12.0005L23.9998 11.9993C23.9998 11.9993 19.9998 11.9995 15.9998 3.99949L15.9994 3.99887C14.9996 2.00012 13.9996 0.0011664 11.9998 0.000732422C9.99976 0.000760649 8.99976 2.00068 7.99986 4.00056L7.99976 4.00066Z"
					fill="currentColor"
				/>
			</svg>
		</div>
		<slot> </slot>
	</div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
	data: function () {
		return {
			popper: null,
			currentlyActive: false,
		};
	},

	watch: {
		target: {
			handler: function () {
				this.$nextTick(() => {
					this.setup();
				});
			},
			immediate: true,
		},
		active: {
			handler: function () {
				this.currentlyActive = this.active;
			},
			immediate: true,
		},
	},

	props: {
		target: {
			default: null,
		},
		active: {
			type: Boolean,
			default: false,
		},
		placement: {
			type: String,
			default: 'bottom',
		},
		offset: {
			type: Array,
			default: function () {
				return [0, 16];
			},
		},
		buttonSelector: {
			type: String,
			default: '',
		},
	},

	computed: {
		modifiers: function () {
			let fallbackPlacements = ['top', 'bottom'];

			let modifiers = [
				{
					name: 'offset',
					options: {
						offset: this.offset,
					},
				},
				{
					name: 'arrow',
					options: {
						padding: 6,
					},
				},
				{
					name: 'flip',
					options: {
						fallbackPlacements: fallbackPlacements,
					},
				},
			];
			return modifiers;
		},
	},

	methods: {
		generateGetBoundingClientRect: function (x = 0, y = 0) {
			return () => ({
				width: 0,
				height: 0,
				top: y,
				right: x,
				bottom: y,
				left: x,
			});
		},
		setup: function () {
			let popper = this.$refs.popup;
			let virtualElement;
			let options = {
				placement: this.placement,
				modifiers: this.modifiers,
			};
			if (!this.target) {
				return;
			}
			if (this.target) {
				let t = this.target;
				if (typeof this.target === 'string') {
					t = document.querySelector(this.target);
				}
				virtualElement = t;
			} else {
				virtualElement = this.target;
				// virtualElement = document.querySelector(".c-grid");
				// virtualElement = {
				// 	getBoundingClientRect: this.generateGetBoundingClientRect(),
				// };
			}
			this.popper = createPopper(virtualElement, popper, options);
		},
		start: function () {
			this.currentlyActive = true;
		},
		stop: function () {
			this.$emit('onClose');
			//this.currentlyActive = false;
		},
		update: function () {},
		onKeydown: function (e) {
			if (e.code === 'Escape') {
				this.stop();
			}
		},
		onMousedown: function (e) {
			if (e.target.closest('.c-popup')) {
				return;
			}

			if (this.buttonSelector && this.buttonSelector.length && e.target.closest(this.buttonSelector)) {
				return;
			}
			this.stop();
		},
		addEventListeners: function () {
			window.addEventListener('keydown', this.onKeydown);
			window.addEventListener('mousedown', this.onMousedown);
		},
		removeEventListeners: function () {
			window.removeEventListener('keydown', this.onKeydown);
			window.removeEventListener('mousedown', this.onMousedown);
		},
	},

	mounted: function () {
		this.addEventListeners();
	},
	beforeDestroy: function () {
		this.removeEventListeners();
	},
};
</script>

<style lang="scss">
.c-popup {
	width: 240px;
	z-index: var(--z-4);
	background-color: var(--color-bg-4);
	border-radius: var(--border-radius-lg);
	box-shadow: var(--box-shadow);
	opacity: 0;
	transform: scale(0);
	pointer-events: none;

	color: var(--font-color);

	&.active {
		opacity: 1;
		transform: scale(1);
		pointer-events: all;
	}

	&__arrow {
		display: inline-flex;
		width: 24px;
		height: 12px;
		top: -12px;

		color: var(--color-bg-4);

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&[data-popper-placement='top'] {
		.c-popup__arrow {
			top: initial;
			bottom: -12px;

			svg {
				transform: rotate(180deg);
			}
		}
	}
}
</style>
