<template>
	<div class="c-block-view-transcript">
		<template v-if="transcript && transcript.status === 'DONE'">
			<TranscriptView :texts="texts"></TranscriptView>
		</template>
		<template v-if="transcript && transcript.status === 'ACTIVE'">
			<small> Transcript creation in process. Check back in a few minutes. </small>
		</template>
		<template v-if="transcript && transcript.status === 'ERROR'">
			<small> Transcript couldn't be created. This might be because the video doesn't have voice in it. </small>
		</template>
		<template v-if="!block.transcriptId && !tempTranscript">
			<button type="button" class="btn btn-icon btn-primary btn-box" @click="generateTranscript">
				<svg v-if="!processing" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3 9C3 6.23858 5.23858 4 8 4H16C18.7614 4 21 6.23858 21 9V15C21 17.7614 18.7614 20 16 20H3V9Z"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M7.125 12C7.125 12.4832 7.51675 12.875 8 12.875C8.48325 12.875 8.875 12.4832 8.875 12C8.875 11.5168 8.48325 11.125 8 11.125C7.51675 11.125 7.125 11.5168 7.125 12ZM11.125 12C11.125 12.4832 11.5168 12.875 12 12.875C12.4832 12.875 12.875 12.4832 12.875 12C12.875 11.5168 12.4832 11.125 12 11.125C11.5168 11.125 11.125 11.5168 11.125 12ZM15.125 12C15.125 12.4832 15.5168 12.875 16 12.875C16.4832 12.875 16.875 12.4832 16.875 12C16.875 11.5168 16.4832 11.125 16 11.125C15.5168 11.125 15.125 11.5168 15.125 12Z"
						fill="currentColor"
						stroke="currentColor"
						stroke-width="0.75"
						stroke-linecap="square"
					/>
				</svg>
				<span v-else class="c-spinner"> </span>
				<span>
					<template v-if="!processing"> Generate </template>
					<template v-else> Generating </template>
					transcript
				</span>
			</button>
		</template>
	</div>
</template>

<script>
import TranscriptView from './transcript-view';

export default {
	components: {
		TranscriptView,
	},

	data: function () {
		return {
			processing: false,
			expanded: false,
			tempTranscript: null,
		};
	},

	props: {
		block: {},
	},

	computed: {
		transcript: function () {
			if (this.tempTranscript) {
				return this.tempTranscript;
			}
			if (this.block && this.block.transcript) {
				return {
					...this.block.transcript,
				};
			}

			return null;
		},
		texts: function () {
			if (!this.transcript) {
				return null;
			}

			if (!this.transcript.text) {
				return null;
			}

			return this.transcript.text;
		},
	},

	methods: {
		expand: function () {
			this.expanded = true;

			let payload = {
				name: `User viewed transcript`,
			};

			this.$emit('event', payload);
		},
		async generateTranscript() {
			if (this.processing === true) {
				return;
			}
			this.processing = true;
			let id = this.block.id;

			let form = {
				blockId: id,
			};

			let block = await this.$store.dispatch('ads/generateTranscript', form);

			if (block.transcript) {
				this.tempTranscript = block.transcript;
			}

			this.processing = false;
		},
	},
};
</script>

<style lang="scss">
.c-block-view-transcript {
	p {
		font-size: var(--font-size-sm);
		font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0, 'cv01' 0,
			'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl', 'kern';
	}

	.c-spinner {
		margin-left: 4px;
	}

	small {
		display: block;
		margin: var(--margin) 0;
		padding: var(--margin-lg);
		background-color: var(--color-bg-2);
		border-radius: var(--border-radius);
	}
}
</style>
