<template>
	<div class="c-link-explainer">
		<strong>Ad CTA link flow</strong>

		<!-- Stage 1 -->
		<!-- <div class="c-link-explainer__stage">
			<span>1</span>
			<p>Source</p>
		</div> -->

		<h6>
			<label>1</label>
			Source
		</h6>
		<article class="article-text-sm">
			{{ sourceText }}
			<br />
			{{ ad.block.meta.cta }} CTA button
		</article>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
			<line x1="12" y1="5" x2="12" y2="19"></line>
			<polyline points="19 12 12 19 5 12"></polyline>
		</svg>

		<!-- Stage 2 -->
		<template>
			<h6><label>2</label>Destination</h6>

			<article class="article-text-sm">
				{{ ctaLink }}
			</article>

			<a class="btn btn-box btn-icon" v-if="ctaLink" :href="ctaLink" target="_blank">
				<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10 5.5L10.7514 4.75837C13.0959 2.41387 16.8971 2.41388 19.2416 4.75837C21.5861 7.10287 21.5861 10.9041 19.2416 13.2486L18.5 14M5.5 10L4.75837 10.7514C2.41387 13.0959 2.41388 16.8971 4.75838 19.2416C7.10287 21.5861 10.9041 21.5861 13.2486 19.2416L14 18.5M10 14L14 10"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>

				<span>Landing page</span>
			</a>
		</template>
	</div>
</template>

<script>
import Copy from './copy';

export default {
	components: {
		Copy,
	},

	props: {
		ad: {},
	},

	computed: {
		ctaLink: function () {
			return this.ad.block.ctaLink;
		},
		hasScreenshots: function () {
			if (!this.ad.block.screenshotBlock) {
				return false;
			}
			return true;
		},
		sourceText: function () {
			let type = this.ad.block.type;
			let suffix = ``;

			if (type === 'facebook') {
				type = 'Meta';
				let networks = this.ad.block.networks;
				networks = networks.join(', ');

				suffix = `, shown on ${networks}.`;
			}

			if (type === 'linkedin') {
				type = 'Linkedin';
			}

			let str = `${type} Ad${suffix}`;

			return str;
		},
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
	},
};
</script>

<style lang="scss">
.c-link-explainer {
	> strong {
		display: inline-block;
		opacity: 0.8;
		font-size: var(--font-size-sm);
		padding-right: var(--margin);
		margin-bottom: var(--margin-lg);
	}

	> svg {
		margin-top: var(--margin);
		margin-bottom: var(--margin);
	}

	h6 {
		margin-top: 0 !important;
		margin-bottom: 0;

		svg {
			margin: 0 !important;
		}
	}

	.btn {
		margin-top: var(--margin-sm);
		> svg {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	label {
		margin-right: var(--margin);
		margin-bottom: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
		background-color: hsla(var(--p), 70%, 70%, 0.15);
		border-radius: var(--border-radius-full);
	}

	&__stage {
		padding: var(--margin);
		border: var(--color-bg-4) solid 1px;
		border-radius: var(--border-radius-lg);

		> span {
			font-family: var(--font-family-monospace);
		}
	}
}
</style>
