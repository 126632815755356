<template>
	<div class="c-ad-share">
		<div class="c-ad-share__tabs">
			<a v-for="link in links" :key="link.slug" href="#" @click="currentLink = link.slug" :class="[{ active: link.slug === currentLink }]">
				{{ link.text }}
			</a>
		</div>
		<div class="c-ad-share__content">
			<template v-if="currentLink === 'link'">
				<strong> Sharing via a link </strong>
				<p>Copy this link and paste it inside any Slack chat.</p>
				<Copy :text="link"></Copy>
			</template>

			<template v-if="currentLink === 'notion'">
				<strong> Sharing on Notion </strong>
				<ol>
					<li>
						<p>Press <strong>/</strong> to open up the list of blocks.</p>
					</li>
					<li>
						<p>Search for the <strong>embed field</strong></p>
					</li>
					<li>
						<p>Enter this url inside the embed field.</p>
						<Copy :text="link"></Copy>
					</li>
					<li>
						<p><strong>Optional:</strong> Resize the embed view to better match the Ad dimensions.</p>
						<!-- <img src="/images/example-notion-embed.png" />
            <small>Example Notion embed after resizing.</small> -->
					</li>
				</ol>
			</template>

			<template v-if="currentLink === 'clickup'">
				<strong> Sharing on Clickup </strong>
				<ol>
					<li>
						<p>Click <strong>+ View</strong> in the Views Bar.</p>
					</li>
					<li>
						<p>Select <strong>Embed</strong> and enter this url.</p>
						<Copy :text="link"></Copy>
					</li>
					<li>
						<p><strong>Optional:</strong> Name your view using the <strong>Enter name</strong> field in the upper-left corner.</p>
					</li>
				</ol>
			</template>

			<template v-if="currentLink === 'embed'">
				<strong> Sharing on Clickup </strong>
				<p>Copy and paste this snippet inside your html webpage or any wysiwyg editor inside a CMS such as Wordpress, Wix, etc.</p>
				<Copy :text="iframeCode"></Copy>
			</template>
		</div>
	</div>
</template>

<script>
import Copy from '@swipekit/components/utils/copy';

export default {
	components: {
		Copy,
	},

	data: function () {
		return {
			currentLink: 'link',
			links: [
				{
					slug: 'link',
					text: 'Share via link',
				},
				{
					slug: 'notion',
					text: 'Sharing on Notion',
				},
				{
					slug: 'clickup',
					text: 'Sharing on Clickup',
				},
				{
					slug: 'embed',
					text: 'Embed inside a webpage',
				},
			],
		};
	},
	props: {
		ad: {},
	},

	computed: {
		link: function () {
			let ad = this.ad;
			if (!ad) {
				return '';
			}

			return `https://swipekit.app/block/${ad.id}`;
		},
		iframeCode: function () {
			let ad = this.ad;

			if (!ad) {
				return;
			}

			let assetRatio = 1;
			if (ad.block && ad.block.assetRatio && ad.block.assetRatio !== 1) {
				assetRatio = ad.block.assetRatio;
			} else if (ad.block && ad.block.assets && ad.block.assets[0]) {
				let ratio = ad.block.assets[0].ratio || ad.block.assets[0].assetRatio;

				if (ratio) {
					assetRatio = ratio;
				}
			}

			let width = 266;
			let headerHeight = 53;
			let footerHeight = 88;
			let height = Math.round(width * assetRatio) + headerHeight + footerHeight;
			let link = this.link;
			let attr = `title="Saved using Swipekit. Learn more at swipekit.app" allowtransparency="true" scrolling="yes" frameborder="0"`;
			let str = `<iframe width="${width}" height="${height}" src="${link}" ${attr}>\n\n</iframe>`;

			return str;
		},
	},
};
</script>

<style lang="scss">
.c-ad-share {
	.input-link {
		width: 100%;
		display: block;
		background-color: var(--color-bg-1);
		padding: var(--margin) var(--spacer-sm);
		border-radius: var(--border-radius);
		border: var(--color-input-background) solid 1px;
	}

	img {
		display: block;
		max-width: 500px;
		max-width: 400px;
		width: auto;
		height: auto;
		border-radius: 5px;
		border: var(--color-bg-4) solid 1px;
	}

	&__tabs {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;

		a {
			display: inline-block;
			padding: var(--margin-lg);
			font-weight: 450;
			color: var(--color-font) !important;
			border-bottom: var(--color-bg-4) solid 3px;
			text-decoration: none !important;

			&:hover,
			&.active {
				background-color: hsla(var(--p), 65%, 65%, 0.1);
				border-bottom-color: var(--color-primary);
			}

			p {
				margin-bottom: 0;
				opacity: 0.75;
				font-size: var(--font-size-sm);
				font-weight: 400;
			}
		}
	}

	&__content {
		padding: var(--spacer-sm) 0;
	}
}
</style>
